<template>
  <div>
    <el-card class="intanle" v-if="!istanchuang">
      <span class="h1">ユーザー管理リスト</span>
    </el-card>
    <el-card class="ft-14">
      <el-row>
        <el-col>
          <span>ユーザー名：</span>
          <el-input v-model="name" placeholder="ユーザー名を入力してください" style="width: 19%"></el-input>
          <span style="margin-left: 15px">電話番号：</span>
          <el-input v-model="mobile" placeholder="電話番号を入力してください" style="width: 19%"></el-input>
          <span style="margin-left: 15px">メールアドレス：</span>
          <el-input v-model="email" placeholder="メールアドレスを入力してください" style="width: 19%"></el-input>
          <span style="float: right; z-index: 5; position: relative">
            <el-button style="margin-left: 20px;border: none" type="primary" @click="searchList">検索</el-button>
            <el-button type="info" style="border: none" @click="resetList">リセット</el-button>
          </span>
          <p v-if="!istanchuang">
            <span style="float: left">
              <el-button @click="toinsert" type="primary" icon="el-icon-plus" style="background-color: #2a4b9b; border: none">新規</el-button>
            </span>
          </p>
        </el-col>
      </el-row>
    </el-card>
    <!-- リストカードエリア -->
    <el-card>
      <el-table
        max-height="550px"
        @current-change="selectThisLine"
        :highlight-current-row="istanchuang"
        ref="multipleTable"
        v-loading="loading"
        :data="userlist"
        :header-cell-style="{ 'text-align': 'center', color: '#333', 'font-size': '14px' }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        border
        stripe
      >
        <el-table-column label="ユーザー名" prop="userName"></el-table-column>
        <el-table-column label="電話番号" prop="mobile"></el-table-column>
        <el-table-column label="メールアドレス" prop="email"></el-table-column>
        <el-table-column label="作成日時" prop="createTime" width="150"></el-table-column>
        <el-table-column label="最終ログイン時間" prop="lastLoginTime" width="150"></el-table-column>
        <el-table-column label="最終ログインIP" prop="lastAccessIp" width="150"></el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click.native.stop="handleupdate(scope.$index, scope.row)" type="text" size="small" class="el-icon-edit" style="color: #1890ff">編集</el-button>
            <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="small" class="el-icon-delete" style="color: #da0909">削除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- ページング -->
      <el-pagination v-if="total > 10" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"> </el-pagination>
      <div style="clear:both;"></div>
    </el-card>
  </div>
</template>

<script>
import userService from '@/api/user/userService'

export default {
  props: {
    istanchuang: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userService: new userService(), //インターフェース定義
      name: '', //ユーザー名
      mobile: '', //電話番号
      email: '', //メールアドレス
      currentPage: 1, //ページ数
      pageSize: 10, //1ページあたりの件数
      total: 0, //合計数
      userlist: [], //ユーザーリストデータ
      loading: true, //ローディング
      userId: '', //ユーザーID
      multipleSelection: [], //複数選択されたデータ
      item: [], //子から親への通信パラメータ
      merchantlist: [] //商店リスト
    }
  },
  created() {
    this.role = window.localStorage.getItem('role')
    this.GetUsersList()
  },
  methods: {
    // チェックボックスの値を取得
    handleSelectionChange(val) {
      if (val.length != 0) {
        this.multipleSelection = val
        this.userId = val[0].userId
      }
    },
    // 親のポップアップで選択されたデータ
    selectThisLine(row) {
      this.item = row
      this.$emit('submitForm')
      // return item
    },
    // 親に渡す
    passFather() {
      return this.item //this.ruleFormは子コンポーネントで入力されたデータ
    },
    /* ページサイズ変更イベントのリスナー */
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.GetUsersList()
    },
    /* ページ番号変更イベントのリスナー */
    handleCurrentChange(newPage) {
      this.currentPage = newPage
      this.GetUsersList()
    },
    //リスト取得
    async GetUsersList() {
      const date = {
        accessToken: localStorage.getItem('authToken'),
        currentRole: localStorage.getItem('currentRole'),
        userName: this.name, //名前
        mobile: this.mobile, //電話番号
        email: this.email, //メールアドレス
        currentPage: this.currentPage, //ページ数
        pageSize: this.pageSize //1ページあたりの件数
      }
      this.userService.list(date).then(res => {
        if (res.data.success == 'true') {
          this.userlist = res.data.result
          this.loading = false
          this.total = Number(res.data.maxRecords)
          for (var i = 0; i < this.userlist.length; i++) {
            if (this.userlist[i].enabled == 1) {
              this.userlist[i].enabled = true
            } else {
              this.userlist[i].enabled = false
            }
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    //照会
    async searchList() {
      this.currentPage = 1
      this.GetUsersList()
    },
    //詳細ページ遷移
    openDetails(row) {
      this.local()
      this.$router.push({
        name: 'GetUsersDetails',
        query: {
          userId: row.userId,
          type: 'details'
        }
      })
    },
    //変更ページ移動
    handleupdate(index, row) {
      this.local()
      this.$router.push({
        name: 'GetUsersDetails',
        query: {
          userId: row.userId,
          type: 'update'
        }
      })
    },
    //新規追加ページ遷移
    toinsert() {
      this.local()
      this.$router.push({
        name: 'GetUsersDetails',
        query: {
          type: 'insert'
        }
      })
    },
    //リセット
    resetList() {
      this.name = ''
      this.mobile = ''
      this.email = ''
      this.currentPage = 1
      this.GetUsersList()
    },
    //1行削除
    handleDelete(row) {
      var data = [row.userId]
      this.$confirm('この操作はデータを永久に削除します。続行しますか?', '確認', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const date = {
            accessToken: localStorage.getItem('authToken'),
            currentRole: localStorage.getItem('currentRole'),
            userId: row.userId
          }
          this.userService.delete(date).then(res => {
            if (res.data.success == 'true') {
              this.$message({
                type: 'success',
                message: '削除成功!'
              })
              this.GetUsersList()
            } else {
              this.$message.error(res.data.message)
            }
          })
        })
        .catch(err => {
          this.GetUsersList()
        })
    },
    // 複数選択削除
    async handleDelete1() {
      var data1 = this.multipleSelection
      var data2 = []
      if (this.multipleSelection == undefined) {
        this.$confirm('データを選択してください', '表示', {
          type: 'warning'
        })
      } else {
        this.$confirm('この操作はデータを永久に削除します。続行しますか?', '確認', {
          confirmButtonText: '確認',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            for (var i = 0; i < data1.length; i++) {
              data2.push(data1[i].userId)
            }
            // const { data: res } = this.$http.delete(
            //     this.$url.DeleteUser,
            //     {
            //         data: data2,
            //         headers: {
            //             "Content-Type":
            //                 "application/json;charset=UTF-8"
            //         }
            //     }
            // )
            this.$message({
              type: 'success',
              message: '削除成功!'
            })
            this.GetUsersList()
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err
            })
          })
      }
    },
    // 編集時にフィルタ条件をキャッシュに保存
    local() {
      var zph_management_params = {
        name: this.name, //名前
        mobile: this.mobile, //電話番号
        currentPage: this.currentPage, //ページ数
        pageSize: this.pageSize //1ページあたりの件数
      }
      localStorage.setItem('zph_management_params', JSON.stringify(zph_management_params))
    }
  }
}
</script>

<style lang="less" scoped>
.cl-hui {
  color: #b2b2b2 !important;
}

.cl-bl {
  color: #606262;
}

/deep/.el-table td.el-table__cell div {
  /* box-sizing: border-box; */
  margin-top: -2px !important;
}

.userTypeStyle {
  display: inline-block;
  border: 1px solid;
  color: red;
  border-radius: 21px;
  padding: 0 6px;
  margin-left: 10px;
}
</style>
