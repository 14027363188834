import request from "@/utils/service.js"
export default class addressService {
  
  list(data) {
    return request({
      url: 'm_getAddressList',
      method: 'post',
      data: data
    })
  }
  detail(data) {
    return request({
      url: 'm_getAddress',
      method: 'post',
      data: data
    })
  }
  add(data) {
    return request({
      url: 'm_addAddress',
      method: 'post',
      data: data
    })
  }
  update(data) {
    return request({
      url: 'm_updateAddress',
      method: 'post',
      data: data
    })
  }
  delete(data) {
    return request({
      url: 'm_deleteAddress',
      method: 'post',
      data: data
    })
  }
}
