<template>
  <div>
    <el-card class="intanle">
      <span class="h1">{{ title }}</span>
      <span @click="goBack()">
        <el-button class="el-button1" type="info" style="float: right">戻る</el-button>
      </span>
    </el-card>
    <el-card>
      <!-- 情報記入 -->
      <el-form ref="form" label-position="right" label-width="20%" :rules="rules" :model="form">
        <el-row>
          <el-col :span="12">
            <el-form-item label="ユーザー名:" prop="userName">
              <el-input placeholder="请选择商户" v-model="form.userName" :disabled="disabled" style="width: 45%" @focus="table = true"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="受取人氏名:" prop="consigneeName">
              <el-input placeholder="受取人氏名入力" v-model="form.consigneeName" :disabled="disabled" style="width: 45%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="受取人連絡先" prop="consigneeTel">
              <el-input placeholder="受取人連絡先入力" v-model="form.consigneeTel" onkeyup="value=value.replace(/[^\d]/g,'')" :disabled="disabled" style="width: 45%" maxlength="11"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="受取人住所:" prop="consigneeAddress">
              <el-input placeholder="受取人住所入力" v-model="form.consigneeAddress" :disabled="disabled" style="width: 45%"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="デフォルト確認:" prop="isDefault">
              <el-switch :disabled="disabled" v-model="form.isDefault" @change="xiugaiIsDefault"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="logtime" v-if="this.type != 'insert'">
          <el-col :span="12">
            <el-form-item label="作成日時:" label-width="20%">
              {{ createTime }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更新日時:" label-width="20%">
              {{ updateTime }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="作成者:" label-width="20%">
              {{ createPersonName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更新者:" label-width="20%">
              {{ updatePersonName }}
            </el-form-item>
          </el-col>
        </div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="" v-if="this.type != 'details'">
              <el-button type="primary" @click="onSubmit('form')">{{ content }}</el-button>
              <el-button @click="goBack">戻る</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-dialog title="ユーザー名選択" :visible.sync="table" width="80%" center>
      <GetUsersListVue :istanchuang="istanchuang" ref="eidtList" @submitForm="submitForm"></GetUsersListVue>
      <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="table = false">取 消</el-button>
                <el-button type="primary" @click="submitForm">確認</el-button>
            </span> -->
    </el-dialog>
  </div>
</template>

<script>
import GetUsersListVue from '../Users/GetUsersList.vue'
import addressService from '@/api/address/addressService'
export default {
  inject: ['reload'],
  name: 'GetStaffDetails',
  components: {
    GetUsersListVue
  },
  data() {
    return {
      addressService: new addressService(), //インターフェース定義
      istanchuang: true, //親子通信のパラメーター
      table: false, //ポップアップレイヤーの表示
      type: this.$route.query.type, //遷移タイプ
      addressId: this.$route.query.addressId, //配送先住所ID
      loading: false, //ローディング
      form: {
        consigneeName: '', //配送先名
        consigneeTel: '', //配送先電話番号
        consigneeAddress: '', //配送先住所
        userId: '', //ユーザーID
        isDefault: false, //是否为默认地址
        userName: 'ユーザー名選択',
        isZiTi: '0'
      },
      deliveryTypelist: [
        { label: 'いいえ', value: '0' },
        { label: 'はい', value: '1' }
      ],
      title: '', //タイトル
      content: '', //ボタン文字
      MyHeader: {
        accessToken: '',
        fp: ''
      },
      disabled: false, //編集可能確認
      disabled1: false,
      createTime: '', //作成時間
      updateTime: '', //更新時間
      createPersonName: '', //作成者
      updatePersonName: '', //更新者
      headerName: '', //ヘッダー名
      // 非空のバリデーション
      rules: {
        userName: [
          {
            required: true,
            message: 'ユーザー名選択',
            trigger: 'blur'
          }
        ],
        consigneeName: [
          {
            required: true,
            message: '名前入力',
            trigger: 'blur'
          }
        ],
        consigneeAddress: [
          {
            required: true,
            message: '受取人住所入力',
            trigger: 'blur'
          }
        ],
        userId: [
          {
            required: true,
            message: 'ユーザーID入力',
            trigger: 'blur'
          },
          { min: 10, message: 'ユーザーIDは10文字以上必要', trigger: 'blur' }
        ],
        consigneeTel: [
          {
            required: true,
            message: '連絡先入力',
            trigger: 'blur'
          },
          { min: 11, max: 11, message: '連絡先フォーマットが正しくない', trigger: 'blur' }
        ]
        //
      }
    }
  },

  created() {
    this.MyHeader.accessToken = localStorage.getItem('accessToken')
    this.MyHeader.fp = localStorage.getItem('fp')
    if (this.type == 'details') {
      this.title = '受取人住所'
      this.headerName = '受取人住所'
      this.disabled = true
      this.disabled1 = true
      this.GetSlideDetail()
    } else if (this.type == 'update') {
      this.title = '受取人住所修正'
      this.headerName = '受取人住所修正'
      this.content = '保存'
      this.disabled1 = true
      this.GetSlideDetail()
    } else if (this.type == 'insert') {
      this.title = '新規情報'
      this.headerName = '新規受取人住所'
      this.content = '新規追加'
    }
  },

  methods: {
    //受取人住所詳細取得
    async GetSlideDetail() {
      const date = {
        accessToken: localStorage.getItem('authToken'),
        currentRole: localStorage.getItem('currentRole'),
        addressId: this.addressId //受取人住所ID
      }
      this.addressService.detail(date).then(res => {
        if (res.data.success == 'true') {
          this.form = res.data.result
          this.createTime = res.data.result.createTime
          this.updateTime = res.data.result.updateTime
          this.createPersonName = res.data.result.createPerson
          this.updatePersonName = res.data.result.updatePerson
          if (this.form.isDefault == 0) {
            this.form.isDefault = false
          } else {
            this.form.isDefault = true
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    //新規受取人住所
    async onSubmit(formName) {
      // console.log(formName)
      if (this.form.isDefault) {
        this.form.isDefault = '1'
      } else {
        this.form.isDefault = '0'
      }
      if (this.type == 'insert') {
        this.$refs[formName].validate(async valid => {
          if (valid) {
            const date = {
              accessToken: localStorage.getItem('authToken'),
              currentRole: localStorage.getItem('currentRole'),
              consigneeName: this.form.consigneeName,
              consigneeTel: this.form.consigneeTel,
              consigneeAddress: this.form.consigneeAddress,
              isDefault: this.form.isDefault,
              userId: this.form.userId
            }
            this.addressService.add(date).then(res => {
              if (res.data.success == 'true') {
                this.$message.success('保存成功')
                this.goBack()
              } else {
                this.$message.error(res.data.message)
              }
            })
          }
        })
      } else if (this.type == 'update') {
        this.UpdateSlide(formName)
      }
    },

    xiugaiIsDefault(val) {
      if (val) {
        this.form.isDefault = val
      } else {
        this.form.isDefault = val
      }
    },
    //受取人住所修正
    async UpdateSlide(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const date = {
            accessToken: localStorage.getItem('authToken'),
            currentRole: localStorage.getItem('currentRole'),
            addressId: this.addressId,
            consigneeName: this.form.consigneeName,
            consigneeTel: this.form.consigneeTel,
            consigneeAddress: this.form.consigneeAddress,
            isDefault: this.form.isDefault,
            userId: this.form.userId
          }
          // if (this.form.pictureId == '') {
          //   this.$message.error('请上传分类图片！');
          //   return false;
          // }
          this.addressService.update(date).then(res => {
            if (res.data.success == 'true') {
              this.$message.success('変更成功')
              this.goBack()
            } else {
              this.$message.error(res.data.message)
            }
          })
        } else {
          return false
        }
      })
    },
    //前のページに戻る
    goBack() {
      localStorage.setItem('updateState', true)
      this.$router.go(-1)
    },
    // ポップアップを開く
    openme() {
      if (this.type == 'insert') {
        this.table = true
      }
    },
    // ポップアップの確認ボタン
    submitForm() {
      // 获取子组件填写的内容
      var item = this.$refs['eidtList'].passFather()
      this.form.userName = item.userName
      this.form.userId = item.userId
      this.table = false
    }
  }
}
</script>

<style lang="less" scoped>
.el-button1 {
  margin-top: 2px;
}

.el-card {
  margin-top: 15px;
}

textarea {
  height: 100px;
}
</style>
